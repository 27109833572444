<template>
  <div>
    <v-row justify="center">
      <v-col sm="12" md="2">
        <h4 @click="toggleFilters" class="pointer primary--text"
          >{{ filterText }}
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="filters" :disabled="disabled">
      <v-card :disabled="disabled">
        <h3 v-if="disabled"
          >Please Save or Undo your changes before applying filters</h3
        >
        <v-row justify="end">
          <v-col sm="12" md="2">
            <BaseDatePickerWithText
              label="Search Start Date"
              hint="based on Invoice Date"
              clearable
              v-model="searchStartDate"
            >
            </BaseDatePickerWithText>
          </v-col>
          <v-col sm="12" md="2">
            <BaseDatePickerWithText
              hint="based on Invoice Date"
              label="Search End Date"
              clearable
              v-model="searchEndDate"
            >
            </BaseDatePickerWithText>
          </v-col>
          <!-- //templateTodo: uncomment to move the search text to here-->
          <v-col class="ml-auto" sm="12" md="2">
            <v-text-field
              v-model="searchText"
              prepend-inner-icon="mdi-text-box-search"
              label="Search"
              hint="DocumentNumber, VendorCustomer, Description, Reference"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- //templateTodo: if you use a custom search dto uncomment this to allow showing of filter params-->
        <!-- The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->
        <v-row>
          <v-col>
            <v-row>
              <v-col sm="6" md="3">
                <ExciseStratum
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Stratum"
                  :items="mappedStrata"
                  v-model="searchStratumID"
                  item-disabled="disabled"
                >
                  <template v-slot:item="{item, index}">
                    <span> {{ item.ID }} - {{ item.Description }} </span
                    ><span v-if="item.StratumNumber"
                      >&nbsp;({{ item.StratumNumber }})</span
                    ></template
                  >
                  ></ExciseStratum
                >
              </v-col>
              <v-col sm="6" md="3">
                <LuExciseIssues
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Issue"
                  v-model="searchIssueID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span>
                    {{ item.ID }} - {{ item.Description }}
                  </span></template
                > -->
                  ></LuExciseIssues
                >
              </v-col>
              <v-col sm="6" md="3">
                <LuExciseTransactionType
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Transaction Type"
                  v-model="searchTransactionTypeID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span>
                    {{ item.ID }} - {{ item.Description }}
                  </span></template
                > -->
                </LuExciseTransactionType>
              </v-col>
              <v-col sm="6" md="3">
                <LuExciseTaxTypes
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Tax Type"
                  v-model="searchTaxTypeID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span> {{ item.ID }} - {{ item.TaxType }} </span></template
                > -->
                </LuExciseTaxTypes>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col sm="6" md="3">
              <LuCounty
                chips
                deletable-chips
                small-chips
                clearable
                multiple
                dense
                label="Sold From County"
                v-model="searchSoldFromCountyID"
              >
                <template v-slot:item="{item, index}">
                  <span> {{ item.ID }} - {{ item.Name }} </span></template
                ></LuCounty
              >
            </v-col> -->
              <v-col sm="6" md="3">
                <LuCounty
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  sortProperty="ID"
                  label="Delivered To County"
                  v-model="searchDeliveredToCountyID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span> {{ item.ID }} - {{ item.Name }} </span>
                  </template
                > -->
                </LuCounty>
              </v-col>
              <v-col sm="6" md="3">
                <LuCity
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  autocomplete="new-password"
                  label="Delivered To City"
                  v-model="searchDeliveredToCityID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span> {{ item.ID }} - {{ item.City }} </span></template
                > -->
                </LuCity>
              </v-col>
              <v-col sm="6" md="3">
                <LuSpecialDistrict
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Special District"
                  v-model="searchSpecialDistrictID"
                >
                  <!-- <template v-slot:item="{item, index}">
                  <span>
                    {{ item.ID }} - {{ item.SpecialDistrict }}
                  </span></template
                > -->
                </LuSpecialDistrict>
              </v-col>
              <v-col sm="6" md="3">
                <v-autocomplete
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Vendor / Customer"
                  :items="filteredVendorCustomers"
                  v-model="searchVendorCustomer"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="3">
                <v-autocomplete
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Reference"
                  :items="filteredReferences"
                  v-model="searchReference"
                ></v-autocomplete>
              </v-col>
              <v-col sm="6" md="3">
                <User
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                  label="Created By"
                  :items="selectedProjectExciseExceptionUsers"
                  v-model="searchCreatedByID"
                ></User>
              </v-col>
              <v-col sm="6" md="3">
                <v-select
                  v-model="searchIsActive"
                  :items="[
                    {text: 'Active', value: 'true'},
                    {text: 'Inactive', value: 'false'},
                  ]"
                  label="Exception Is Active"
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                >
                </v-select>
              </v-col>
              <v-col sm="6" md="3">
                <v-select
                  v-model="searchStratumIsActive"
                  :items="[
                    {text: 'Active', value: 'true'},
                    {text: 'Inactive', value: 'false'},
                  ]"
                  label="Stratum Is Active"
                  chips
                  deletable-chips
                  small-chips
                  clearable
                  multiple
                  dense
                >
                </v-select>
              </v-col>
              <!-- <v-col sm="6" md="3" v-if="showSort">
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="searchSortBy"
                    :items="headers"
                    dense
                    label="Sort by"
                  ></v-select> </v-col
                ><v-col cols="4">
                  <v-btn-toggle v-model="searchDescending" mandatory>
                    <v-btn small depressed color="primary" :value="false">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn small depressed color="primary" :value="true">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col> -->
              <v-col sm="6" md="4">
                <v-btn
                  class="ma-2 mb-5"
                  :disabled="loading || activateDeactivateRunning"
                  color="primary"
                  @click="openActDeactModal('activate')"
                >
                  Activate Filtered Data
                </v-btn>
                <v-btn
                  class="ma-2 mb-5"
                  :disabled="loading || activateDeactivateRunning"
                  color="primary"
                  @click="openActDeactModal('deactivate')"
                >
                  Deactivate Filtered Data
                </v-btn>
              </v-col>
              <v-col class="ml-auto align-end" sm="6" md="4">
                <!-- <v-btn
                class="ma-2 mb-5"
                color="primary"
                :disabled="downloadPending"
                @click="searchExportExciseExceptions"
                ><v-icon dark left>mdi-file-excel</v-icon> Export IDs</v-btn
              > -->
                <v-btn
                  outlined
                  class="ma-2 mb-5 float-right"
                  color="primary"
                  @click="resetFilters"
                  ><v-icon dark left>mdi-filter-off</v-icon> Reset
                  Filters</v-btn
                >
                <v-btn
                  class="ma-2 mb-5 float-right"
                  color="primary"
                  :disabled="downloadPending"
                  @click="searchDetailedExportExciseExceptions"
                  ><v-icon dark left>mdi-file-excel</v-icon> Export Filtered
                  Data</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-dialog persistent v-model="dialog" width="350" scrollable>
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          <span> Confirm? </span>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12"
              ><span>
                Are you sure you want to {{ actDeact }}
                {{ searchTotal }} matching exceptions?
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = !dialog">Cancel</v-btn>
          <v-btn
            v-if="actDeact == 'activate'"
            @click="activateAll"
            outlined
            color="primary"
            >Activate</v-btn
          >
          <v-btn
            v-if="actDeact == 'deactivate'"
            @click="deactivateAll"
            outlined
            color="primary"
            >Deactivate</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {get, sync, commit, call} from 'vuex-pathify'
import User from '@components/select/a-multi-id/User'
import LuCity from '@components/select/a-multi-id/LuCity'
import LuCounty from '@components/select/a-multi-id/LuCounty'
import LuExciseIssues from '@components/select/a-multi-id/LuExciseIssues'
// import LuCounty from '@components/select/a-multi-id/LuCounty'
import LuSpecialDistrict from '@components/select/a-multi-id/LuSpecialDistrict'
import ExciseStratum from '@components/select/a-multi-id/ExciseStratum'
import LuExciseTaxTypes from '@components/select/a-multi-id/LuExciseTaxTypes'
import LuExciseTransactionType from '@components/select/a-multi-id/LuExciseTransactionType'
export default {
  name: 'ExciseExceptionsSearchParams',
  props: {
    showSort: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filters: false,
    filterText: '-Show Filters-',
    dialog: false,
    actDeact: null,
  }),
  components: {
    User,
    LuCity,
    LuCounty,
    LuExciseIssues,
    // LuCounty,
    LuSpecialDistrict,
    ExciseStratum,
    LuExciseTaxTypes,
    LuExciseTransactionType,
  },
  created() {},
  watch: {},
  computed: {
    ...sync('exciseExceptions', [
      'searchText',
      //templateTODO: uncomment as used in ExciseExceptionsSearchParams
      'searchStartDate',
      'searchEndDate',
      'searchStratumID',
      'searchIssueID',
      'searchTransactionTypeID',
      'searchTaxTypeID',
      // 'searchSoldFromCountyID',
      'searchDeliveredToCityID',
      'searchDeliveredToCountyID',
      'searchSpecialDistrictID',
      'searchVendorCustomer',
      'searchReference',
      'searchSortBy',
      'searchDescending',
      // 'searchShowInactive',
      'searchIsActive',
      'searchStratumIsActive',
      // 'searchIsAppended',
      'searchCreatedByID',
    ]),
    ...get('exciseExceptions', [
      'loading',
      'activateDeactivateRunning',
      'downloadPending',
      'searchTotal',
    ]),
    ...get('project', [
      'selectedProjectExciseExceptionVendorCustomerObject',
      'selectedProjectExciseExceptionReferencesObject',
      'selectedProjectExciseExceptionUsers',
      'selectedProjectExciseStratum',
    ]),
    //templateTODO: custom available lookup computed properties should go here
    mappedStrata() {
      return this.selectedProjectExciseStratum.map((item) => {
        return {
          ...item,
          disabled:
            !this.searchStratumIsActive.includes('false') && !item.IsActive,
        }
      })
    },
    filteredReferences() {
      var list = this.selectedProjectExciseExceptionReferencesObject
      if (this.searchTransactionTypeID.length > 0) {
        list = list.filter((x) =>
          this.searchTransactionTypeID.includes(x.TransactionTypeID)
        )
      }
      if (this.searchTaxTypeID.length > 0) {
        list = list.filter((x) => this.searchTaxTypeID.includes(x.TaxTypeID))
      }
      if (this.searchIssueID.length > 0) {
        list = list.filter((x) => this.searchIssueID.includes(x.IssueID))
      }
      var mappedList = [
        'Sales Invoices',
        'Purchase Invoices',
        'Depreciation Schedule',
        'Reconciliation',
      ].concat(
        list.map((item) => {
          return item.Reference
        })
      )

      return mappedList.filter((v, i, a) => a.indexOf(v) == i)
    },
    filteredVendorCustomers() {
      var list = this.selectedProjectExciseExceptionVendorCustomerObject
      if (this.searchTransactionTypeID.length > 0) {
        list = list.filter((x) =>
          this.searchTransactionTypeID.includes(x.TransactionTypeID)
        )
      }
      if (this.searchTaxTypeID.length > 0) {
        list = list.filter((x) => this.searchTaxTypeID.includes(x.TaxTypeID))
      }
      if (this.searchIssueID.length > 0) {
        list = list.filter((x) => this.searchIssueID.includes(x.IssueID))
      }
      var mappedList = list.map((item) => {
        return item.VendorCustomer
      })
      return mappedList.filter((v, i, a) => a.indexOf(v) == i)
    },
  },
  methods: {
    ...call('exciseExceptions', [
      'activateDeactivateExciseExceptions',
      'searchExportExciseExceptions',
      'searchDetailedExportExciseExceptions',
    ]),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    activateAll() {
      this.activateDeactivateExciseExceptions({active: true})
      this.actDeact = null
      this.dialog = false
    },
    deactivateAll() {
      this.activateDeactivateExciseExceptions({active: false})
      this.actDeact = null
      this.dialog = false
    },
    openActDeactModal(type) {
      this.actDeact = type
      this.dialog = true
    },
    resetFilters() {
      this.searchPage = 1
      this.searchRowsPerPage = 100
      this.searchSortBy = 'ID'
      this.searchDescending = true
      this.searchText = ''
      this.searchStartDate = null
      this.searchEndDate = null
      this.searchStratumID = []
      this.searchIssueID = []
      this.searchTransactionTypeID = []
      this.searchTaxTypeID = []
      this.searchDeliveredToCountyID = []
      this.searchDeliveredToCityID = []
      this.searchSpecialDistrictID = []
      this.searchVendorCustomer = []
      this.searchReference = []
      this.searchIsActive = []
      this.searchStratumIsActive = []
      this.searchCreatedByID = []
    },
  },
}
</script>

<style lang="scss">
</style>